<template>
    <v-card flat outlined class="pa-0">
        <v-tabs grow v-model="tab" slider-size="2">
            <v-tab v-for="(option, index) in settings" :key="index + '_ts_tab'">
                <country-flag v-if="option.country" :country="option.country" />
                <template v-else>{{ option.locale }}</template>
            </v-tab>
        </v-tabs>

        <v-divider class="ma-0"></v-divider>
        <v-card-text class="py-2">
            <v-tabs-items v-model="tab">
                <v-tab-item v-for="(option, index) in settings" :key="index + '_ts_tab_item'">
                    <v-text-field :key="index + '_input_item'" v-if="mode === 'input'" v-model="translations[option.locale]"
                        :outlined="outlined" :dense="dense" :disabled="disabled" :rules="rules" :label="option.label"
                        @focus="handleFocus" :data-layout="dataLayout" @input="handleInput" class="my-2">
                    </v-text-field>

                    <v-textarea :key="index + '_textarea_item'" v-else-if="mode === 'textarea'" :rows="rows"
                        v-model="translations[option.locale]" :outlined="outlined" :dense="dense" :disabled="disabled"
                        :rules="rules" :label="option.label" @focus="handleFocus" @input="handleInput" :data-layout="dataLayout" class="my-2">
                    </v-textarea>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>
<script>
/**
 * TranslationInput component allows users to input translations for different locales.
 *
 * @component TranslationInput
 *
 * @prop {Array} rules - An array of validation rules for the input fields.
 * @prop {Boolean} dense - Determines whether the input fields should have a dense style.
 * @prop {Boolean} outlined - Determines whether the input fields should have an outlined style.
 * @prop {Boolean} disabled - Determines whether the input fields should be disabled.
 * @prop {String} dataLayout - The layout of the screen keyboard.
 * @prop {Number|String} rows - The number of rows for the textarea input field.
 * @prop {Object} value - The object containing the translations for each locale.
 * @prop {Array} settings - An array of objects representing the available locales and their labels and country flags.
 * @prop {String} settings.locale - The locale code for the language.
 * @prop {String} settings.label - The label for the language.
 * @prop {String} settings.country - The country code for the language.
 * @prop {String} mode - The mode of the input field ('input' or 'textarea').
 *
 * @event input - Emitted when the translations are updated.
 * @event focus - Emitted when the input field is focused.
 *
 * @example
 * <TranslationInput
 *   :rules="validationRules"
 *   :dense="true"
 *   :outlined="false"
 *   :disabled="false"
 *   dataLayout="vertical"
 *   :rows="3"
 *   :value="translations"
 *   :settings="locales"
 *   mode="textarea"
 *   @input="updateTranslations"
 *   @focus="handleFocus"
 * />
 */
import CountryFlag from "vue-country-flag";
export default {
    name: "TranslationInput",
    components: { CountryFlag },
    props: {
        rules: Array,
        dense: Boolean,
        outlined: Boolean,
        disabled: Boolean,
        dataLayout: String,
        rows: [Number, String],
        value: {
            type: Object,
            default: null
        },
        settings: {
            type: Array,
            default: () => {
                return [
                    { locale: "de", label: "Deutsch", country: "DE" },
                    { locale: "en", label: "English", country: "GB" },
                    { locale: "fr", label: "Français", country: "FR" },
                    { locale: "ar", label: "العربية", country: "SA" },
                ]
            },
            validator: (value) => {
                if (value) {
                    return !value.some(item => !item.locale || typeof item.locale !== 'string');
                }

                return true;
            },
        },
        mode: {
            type: String,
            default: "input",
            validator: (value) => ['input', 'textarea'].includes(value)
        },
    },
    data: () => {
        return {
            translations: {},
            tab: 0,
        }
    },
    watch: {
        value: {
            handler(value) {
                this.getTranslations();
            },
            deep: true
        }
    },
    computed: {

    },
    methods: {
        handleInput() {
            this.$emit('input', this.translations);
        },
        handleFocus(e) {
            this.$emit('focus', e);
        },
        assignTranslations() {
            let trs = {};
            this.settings.map(item => {
                trs[item.locale] = this.value && this.value.hasOwnProperty(item.locale) ? this.value[item.locale] : null;
            });
            this.translations = Object.assign({}, trs);
        },
        getTranslations() {
            if (this.value && typeof this.value === 'object') {
                const locales = this.settings.map(item => item.locale);
                const valueKeys = Object.keys(this.value);
                const missingLocales = locales.filter(locale => !valueKeys.includes(locale));
                if (missingLocales.length > 0) {
                    console.warn("Missing translations for locales:", missingLocales);
                    this.assignTranslations();
                } else {
                    this.translations = Object.assign({}, this.value);
                }
            } else {
                this.assignTranslations();
            }
        }
    },
    mounted() {
        this.getTranslations();
    }
}

</script>
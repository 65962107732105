<template>
    <v-dialog fullscreen scrollable persistent :value="true">
        <v-card tile>
            <v-card-title :class="[$vuetify.theme.dark ? 'dark-bg' : '']" class="card-header pt-1">
                {{ title }}
                <v-spacer />
                <v-btn @click="closeDialog" class="ma-0" text color="error">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="px-1 py-10 overflow-y-auto">
                <v-form v-model="valid" lazy-validation ref="priceCategoryForm">
                    <v-row dense class="mx-0">
                        <v-col cols="12">
                            <v-text-field v-model="form.name" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                :label="$t('generic.lang_name')" outlined dense :rules="[rules.required]"
                                @focus="showTouchKeyboard" />
                        </v-col>
                        <v-col cols="12">
                            <TranslationInput v-model="form.translation" outlined dense @focus="showTouchKeyboard"
                                :settings='[
                                    { locale: "de", label: $t("generic.lang_name", "de"), country: "DE" },
                                    { locale: "en", label: $t("generic.lang_name", "en"), country: "GB" },
                                    { locale: "fr", label: $t("generic.lang_name", "fr"), country: "FR" },
                                    { locale: "ar", label: $t("generic.lang_name", "ar"), country: "SA" },
                                ]' :data-layout="KEYBOARD.KEYSETS.NORMAL" :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model.number="form.max" type="number" :min="0" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="loading"
                                :label="$t('eventbee.lang_maxTicket')" outlined dense
                                @focus="showTouchKeyboard" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <strong>{{ $t('generic.lang_color') }}:</strong>
                            <swatches inline v-model="form.color"></swatches>
                        </v-col>
                        <v-col cols="12">
                            <TicketsPriceListComponent v-model="form.price_list" :requiresBooking="false" />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions :class="[$vuetify.theme.dark ? 'dark-bg' : '']" class="card-footer text-right pt-1 pb-1">
                <v-spacer />
                <v-btn @click="closeDialog" class="bg-danger text-light mx-0">
                    {{ $t("generic.lang_cancel") }}
                </v-btn>

                <v-btn :disabled="loading" :loading="loading" @click="save" class="bg-primary text-light mx-1">
                    {{ $t("generic.lang_save") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns"
import TranslationInput from "@/components/common/TranslationInput.vue";
import Swatches from "vue-swatches";
import validation from '@/mixins/validation/validation';
import TicketsPriceListComponent from '../TicketsPriceListComponent.vue';

export default {
    name: "CreateEditSeatCategoryComponent",
    components:{
        TranslationInput,
        TicketsPriceListComponent,
        Swatches
    },
    mixins: [mixin, validation],
    props: {
        edit: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: "",
        },
    },
    data(){
        return {
            valid: false,
            loading: false,
            hasToRefresh: false,
            form: {
                id: null,
                name: null,
                color: null,
                max: null,
                price_list: [],
                translation: null
            }
        }
    },
    computed:{
        title() {
            return this.edit ? this.$t('generic.lang_edit') + ' ' + this.$t('eventbee.lang_seatCategory') : this.$t('generic.lang_create') + ' ' + this.$t('eventbee.lang_seatCategory');
        },
    },
    methods: {
        closeDialog(){
            this.$emit("closeDialog", this.hasToRefresh);
        },
        save() {
            if (!this.$refs.priceCategoryForm.validate()) {
                return
            }
            //this.form.name = this.form.translation.de;
            if (this.edit) {
                this.loading = true;
                this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.SEATCATEGORY.UPDATE,this.form).then((res) => {
                    if (res.data.status === "SUCCESS") {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_success'),
                            color: "success"
                        });
                        this.hasToRefresh = true;
                        this.closeDialog();
                    } else if (res.data.status === "FAILED") {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg,
                            color: "error"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.loading = true;
                this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.SEATCATEGORY.CREATE, this.form).then((res) => {
                    if (res.data.STATUS) {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_success'),
                            color: "success"
                        });
                        this.hasToRefresh = true;
                        this.closeDialog();
                    } else if (res.data.status === "FAILED") {
                        Events.$emit("showSnackbar", {
                            message: res.data.msg,
                            color: "error"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        getSeatCategory(){
            this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.SEATCATEGORY.GET, {
                id: this.id
            }).then((res) => {
                if (res.data.status) {
                    this.form.id = this.id;
                    this.form.name = res.data.data.name;
                    this.form.max = res.data.data.max;
                    this.form.color = res.data.data.color;
                    this.form.price_list = res.data.data.price_list;
                    this.form.translation = res.data.data.translation;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch((err) => {
                Events.$emit("showSnackbar", {
                    message: err.message,
                    color: "error"
                });
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        if(this.edit){
            this.getSeatCategory()
        }
    },
}

</script>
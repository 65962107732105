<template>
  <v-container class="card pa-0" fluid>
    <v-card class="card-body ma-0 pa-0" elevation="0">
      <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
        <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
          {{ $t('eventbee.lang_attributeGroups') }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn @click="dialog = true" color="primary" icon>
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text class="pa-0 ma-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.ATTRIBUTEGROUPS"
            :datatable-headers="datatableHeaders"
            :permissionDelete="true"
            :permissionEdit="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            no-excel-export
            ref="attributeGroups"
            show-delete-buttons
            show-edit-buttons
            show-select-checkbox
        ></Datatable>
      </v-card-text>
    </v-card>

    <v-dialog max-width="500" persistent v-model="dialog">
      <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-3">
          <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title">{{
              this.formTitle
            }}
          </div>
        </div>

        <div class="card-body">
          <v-form lazy-validation v-model="valid" ref="attributesForm">
            <v-text-field v-model="form.name" outlined dense :rules="[rules.required]"
                          :label="$t('generic.lang_name')"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
            />
            <v-select
                :label="$t('generic.lang_type')"
                item-text="name"
                item-value="value"
                v-model="form.type"
                :items="types"
                :rules="[rules.required]"
                dense
                outlined
            />

            <v-combobox
                v-if="form.type === 'options'"
                multiple
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                @focus="showTouchKeyboard"
                v-model="form.attributes"
                :label="$t('generic.lang_attributes')"
                chips
                outlined
                deletable-chips
                hide-details
                class="tag-input"
                :search-input.sync="search"
                @keyup.tab="updateTags"
                @paste="updateTags"
            >
            </v-combobox>
            <v-checkbox
                :label="$t('generic.lang_required')"
                v-model="form.required"
            />
          </v-form>
        </div>
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
          <v-spacer/>
          <v-btn @click="dialog = false" class="btn btn-danger bg-danger text-light mx-auto">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>
          <v-btn :disabled="!valid||loading" :loading="loading" @click="save"
                 class="btn bg-primary text-light btn-primary mx-auto ml-2">
            {{ this.edit ? this.$t('generic.lang_save') : this.$t('generic.lang_create') }}
          </v-btn>
        </div>
      </div>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import validation from "../../../mixins/validation/validation";

export default {
  name: "AttributeGroupsComponent",
  components: {
    Datatable
  },
  mixins: [mixin,validation],
  data: () => {
    return {
      ENDPOINTS,
      search: '',
      dialog: false,
      valid: false,
      form: {
        uuid: 0,
        name: null,
        attributes: null,
        required: false,
        type: 'options',
      },
      defaultItem: {
        uuid: 0,
        name: null,
        attributes: null,
        required: false,
        type: 'options',
      },
      loading: false,
      edit: false,
    }
  },
  watch: {
    dialog(val) {
      val || val & this.close();
    },
  },
  methods: {
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_delete') + ' ' + this.$t('eventbee.lang_attributeGroups'),
        text: this.$t('generic.lang_deleteConfirmationMSG') + ' ' + this.$t('eventbee.lang_attributeGroups'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.ATTRIBUTEGROUP.DELETE, {
            uuids: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.attributeGroups.getDataFromApi();
              self.$refs.attributeGroups.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    updateTags() {
      this.$nextTick(() => {
        if(!(this.form.attributes && Array.isArray(this.form.attributes))){
          this.form.attributes = [];
        }
        this.form.attributes.push(...this.search.split(','));
        this.$nextTick(() => {
          this.search = '';
        });
      });
    },
    getAttribute(uuid){
      this.loading = false;
      this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.ATTRIBUTEGROUP.GET,{
        uuid: uuid
      }).then((res) => {
        if (res.status === 200) {
          this.form = Object.assign({}, res.data);
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      if (!this.$refs.attributesForm.validate()) {
        return
      }
      if (this.edit) {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.ATTRIBUTEGROUP.UPDATE, this.form).then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close();
            this.$refs.attributeGroups.getDataFromApi();
            this.$refs.attributeGroups.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
        });
      } else {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.ATTRIBUTEGROUP.CREATE, this.form).then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close()
            this.$refs.attributeGroups.getDataFromApi();
            this.$refs.attributeGroups.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
          this.form = Object.assign({}, this.defaultItem);
        });
      }
    },
    editEntry(entry) {
      this.edit = true;
      this.dialog = true;
      this.getAttribute(entry.id);
    },
    close() {
      this.edit = false;
      this.dialog = false;
      this.form = Object.assign({}, this.defaultItem);
      // if (this.$refs.attributesForm) this.$refs.attributesForm.reset();
    }
  },
  computed: {
    types(){
      return [
        {
          name:this.$t('generic.lang_text'),
          value:'text'
        },
        {
          name:this.$t('generic.lang_number'),
          value:'number'
        },
        {
          name:this.$t('generic.lang_Options'),
          value:'options'
        },
      ]
    },
    formTitle: function () {
      return this.edit ? this.$t('generic.lang_edit') + ' ' + this.$t('eventbee.lang_attributeGroups') : this.$t('generic.lang_create') + ' ' + this.$t('eventbee.lang_attributeGroups');
    },
    datatableHeaders: function () {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true,
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'name',
          sort: "asc"
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'attributes',
          hide: true,
        },
      ]
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>

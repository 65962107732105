<template>
  <v-container class="card pa-0" fluid>
    <v-card class="card-body ma-0 pa-0" elevation="0">
      <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
        <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
          {{$t('eventbee.lang_eventGroups')}}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn @click="dialog = true" color="primary" icon>
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text class="pa-0 ma-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.EVENTGROUPS"
            :datatable-headers="datatableHeaders"
            :permissionDelete="true"
            :permissionEdit="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            no-excel-export
            ref="eventGroups"
            show-delete-buttons
            show-edit-buttons
            show-select-checkbox
        >
          <template v-slot:item.icon="{item}">
            <font-awesome-icon v-if="item.icon" :icon="['fal', item.icon]" class="mx-auto" size="2x"/>
          </template>
          <template v-slot:item.color="{item}">
            <v-chip :style="{backgroundColor: item.color}"></v-chip>
          </template>
      </Datatable>
      </v-card-text>
    </v-card>

    <v-dialog fullscreen persistent v-model="dialog">
      <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card" style="min-height: 100% !important;">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-3">
          <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title">{{this.formTitle}}</div>
        </div>

        <div class="card-body overflow-y">
          <v-form v-model="valid" ref="eventGroupForm">
            <v-row>
              <v-col cols="12">
                <Imagecropper v-if="dialog" ref="eventGroupImageCropper" v-model="form.image"
                                  @update="form.imageUpdated = true"/>
              </v-col>
              <v-col cols="12" >
                <v-text-field
                  v-model="form.name"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :disabled="loading"
                  :label="$t('generic.lang_name')"
                  outlined
                  dense
                  :rules="[rules.required]"
                  @focus="showTouchKeyboard"
                />
              </v-col>
              <v-col cols="12" >
              <TranslationInput v-model="form.translation" 
                outlined 
                dense
                @focus="showTouchKeyboard"
                :settings='[
                    { locale: "de", label: $t("generic.lang_name","de"), country: "DE" },
                    { locale: "en", label: $t("generic.lang_name","en"), country: "GB" },
                    { locale: "fr", label: $t("generic.lang_name","fr"), country: "FR" },
                    { locale: "ar", label: $t("generic.lang_name","ar"), country: "SA" },
                ]'
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                :rules="[rules.required]"/>

              </v-col>
              <v-col cols="12" md="6" >
                <fontawesome-picker dense :fieldLabel="'Icon'" v-model="form.icon"/>
              </v-col>
              <v-col cols="12" md="6" >
                <v-text-field v-model="form.position"
                              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                              :label="$t('generic.lang_position')"
                              type="number"
                              outlined
                              required dense
                              @focus="showTouchKeyboard"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0" >
                <div class="widget-content text-left pa-0">
                  <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                  <swatches
                      v-model="form.color" inline
                      background-color="transparent"
                  ></swatches>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
          <v-spacer/>
          <v-btn @click="close" class="btn btn-danger bg-danger text-light mx-auto" >
            {{this.$t('generic.lang_cancel')}}
          </v-btn>
          <v-btn :disabled="!valid||loading" :loading="loading" @click="save"
                 class="btn bg-primary text-light btn-primary mx-auto ml-2">
            {{this.edit? this.$t('generic.lang_save') : this.$t('generic.lang_create')}}
          </v-btn>
        </div>
      </div>

      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import FontawesomePicker from "@/components/common/iconPicker";
import Swatches from 'vue-swatches';
import mixin from "@/mixins/KeyboardMixIns"
import validation from "@/mixins/validation/validation";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Imagecropper from "@/components/common/imagecropper";
import TranslationInput from "@/components/common/TranslationInput";

export default {
  name: "EventGroupsComponent",
  components: {
    Datatable,
    FontawesomePicker,
    Imagecropper,
    TranslationInput,
    FontAwesomeIcon,
    Swatches
  },
  mixins: [mixin, validation],
  data: () => {
    return {
      ENDPOINTS,
      dialog: false,
      valid:false,
      form:{
        id:0,
        name:null,
        translation: null,
        icon:null,
        color:null,
        position:null,
        image: null,
        imageUpdated: false,
        deleteImage: false,
      },
      defaultItem:{
        id:0,
        name:null,
        translation: null,
        icon:null,
        color:null,
        position:null,
        image: null,
        imageUpdated: false,
        deleteImage: false,
      },
      loading: false,
      edit: false,
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.close()
      }
    },
  },
  methods: {
    getEventGroup(entry) {
      this.loading = true;
      this.axios
        .post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.GET, {
          id: entry.id,
        })
        .then((res) => {
          Object.assign(this.form, res.data.data);
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: err.message,
            color: 'error',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_delete')+' '+this.$t('eventbee.lang_eventGroup'),
        text: this.$t('generic.lang_deleteConfirmationMSG')+' '+this.$t('eventbee.lang_eventGroup'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.DELETE, {
            id: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.eventGroups.getDataFromApi();
              self.$refs.eventGroups.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    save() {
      if(!this.$refs.eventGroupForm.validate()){
        return
      }

      if (this.$refs["eventGroupImageCropper"]){
        this.$refs.eventGroupImageCropper.cropImage();
      }

      this.form.image = this.form.imageUpdated ? this.form.image : '';
      this.form.deleteImage = this.form.image ? false : true;

      if (this.edit) {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.UPDATE, this.form).then((res) => {
          if (res.data.status ) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close()
            this.$refs.eventGroups.getDataFromApi();
            this.$refs.eventGroups.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.EVENTGROUPS.CREATE, this.form).then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close()
            this.$refs.eventGroups.getDataFromApi();
            this.$refs.eventGroups.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    editEntry(entry) {
      this.edit = true;
      this.dialog = true;
      this.getEventGroup(entry);
    },
    close() {
      this.loading = false;
      this.edit = false;
      this.dialog = false;
      Object.assign(this.form, this.defaultItem);
    }
  },
  computed: {
    formTitle: function () {
      return this.edit ? this.$t('generic.lang_edit')+' '+this.$t('eventbee.lang_eventGroup') : this.$t('generic.lang_create')+' '+this.$t('eventbee.lang_eventGroup');
    },
    datatableHeaders: function () {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: false,
        },
        {
          text: this.$t('generic.lang_name'),
          value: 'name',
          sort: "asc"
        },
        {
          text: this.$t('generic.lang_icon'),
          value: 'icon',
          sort: "asc",
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          align: 'left',
          value: 'color',
          width: 80,
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <v-container class="card pa-0" fluid>
        <v-card class="card-body ma-0 pa-0" elevation="0">
            <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
                <div :class="[$vuetify.theme.dark ? 'white--text' : '']" class="card-title text-capitalize pt-3">
                    {{ $t('eventbee.lang_seatCategories') }}
                </div>

                <div class="btn-actions-pane-right actions-icon-btn">
                    <v-btn @click="dialog = true" color="primary" icon>
                        <v-icon>add</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider class="pa-0 ma-0" />
            <v-card-text class="pa-0 ma-0">
                <Datatable :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.SEATCATEGORIES"
                    :datatable-headers="datatableHeaders" :permissionDelete="true" :permissionEdit="true"
                    @deleteEntries="deleteEntries" @deleteEntry="deleteEntry" @editEntry="editEntry" no-excel-export
                    ref="seatCategories" show-delete-buttons show-edit-buttons show-select-checkbox></Datatable>
            </v-card-text>
        </v-card>
        <CreateEditSeatCategoryComponent v-if="dialog" @closeDialog="closeDialog" :edit="edit" :id="id" />
    </v-container>
</template>
<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import CreateEditSeatCategoryComponent from "./CreateEditSeatCategoryComponent.vue";

export default {
    name: "SeatCategoriesComponent",
    components: {
        Datatable,
        CreateEditSeatCategoryComponent
    },
    data() {
        return {
            ENDPOINTS,
            dialog: false,
            edit: false,
            id: null,
        }
    },
    methods: {
        deleteData(idsToDelete = []) {
            let self = this;
            this.$swal({
                title: this.$t('generic.lang_delete') + ' ' + this.$t('eventbee.lang_seatCategories'),
                text: this.$t('generic.lang_deleteConfirmationMSG') + ' ' + this.$t('eventbee.lang_seatCategories'),
                icon: "warning",
                cancelButtonText: this.$t('generic.lang_cancel'),
                confirmButtonText: this.$t('generic.lang_delete'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                        idsToDelete = [];
                    }
                    this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.SEATCATEGORY.DELETE, {
                        id: idsToDelete,
                    }).then((res) => {
                        if (res.status === 200) {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_success'),
                                color: "success"
                            });
                            self.$refs.seatCategories.getDataFromApi();
                            self.$refs.seatCategories.resetSelectedRows();
                        } else {
                            Events.$emit("showSnackbar", {
                                message: this.$t('generic.lang_errorOccurred'),
                                color: "error"
                            });
                        }
                    });
                },
                allowOutsideClick: () => !this.$swal.isLoading,
            });
        },
        deleteEntry(entry) {
            this.deleteData([entry.id]);
        },
        deleteEntries(entries) {
            this.deleteData(entries);
        },
        editEntry(entry) {
            this.id = entry.id;
            this.edit = true;
            this.dialog = true;
        },
        closeDialog(refresh){
            if(refresh){
                this.$refs.seatCategories.getDataFromApi();
                this.$refs.seatCategories.resetSelectedRows();
            }
            this.dialog = false;
            this.edit = false;
            this.id = null;
        }
    },
    computed: {
        datatableHeaders() {
            return [
                {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    hide: false,
                },
                {
                    text: this.$t('generic.lang_name'),
                    value: 'name',
                    sort: "asc"
                },
                {
                    text: this.$t('generic.lang_color'),
                    align: 'left',
                    value: 'color',
                    width: 80,
                }
            ]
        }
    },
    mounted() {
    }
}
</script>
<template>
  <v-container class="card pa-0" fluid>
    <v-card class="card-body ma-0 pa-0" elevation="0">
      <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
        <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
          {{ $t('eventbee.lang_eventPools') }}
        </div>

        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn @click="dialog = true" color="primary" icon>
            <v-icon>add</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-alert
          width="100%"
          outlined
          type="info"
      >
        {{ $t("eventbee.lang_poolsDescriptionInfo") }}
      </v-alert>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text class="pa-0 ma-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.EVENTBEE.POOLS"
            :datatable-headers="datatableHeaders"
            :permissionDelete="true"
            :permissionEdit="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            no-excel-export
            ref="eventPools"
            show-delete-buttons
            show-edit-buttons
            show-select-checkbox
        ></Datatable>
      </v-card-text>
    </v-card>

    <v-dialog max-width="600" persistent v-model="dialog">
      <div :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''" class="card">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header pt-3">
          <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title">{{
              this.formTitle
            }}
          </div>
        </div>

        <div class="card-body">
          <v-form lazy-validation v-model="valid" ref="eventPoolsForm">
            <v-text-field v-model="form.name" outlined dense :rules="[rules.required]"
                          :label="$t('generic.lang_name')"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          @focus="showTouchKeyboard"
            />
            <v-select
                outlined
                :label="$t('generic.lang_type')"
                item-value="value"
                item-text="name"
                dense
                :rules="[rules.required]"
                :items="types"
                v-model="form.type"
            >
            </v-select>
          </v-form>
          <v-data-table :headers="headers" :items="days" :loading="loading"
                        hide-default-footer class="border"
                        :no-data-text="$t('generic.lang_noEntriesFound')">
            <template v-slot:item.day="{item}">
              {{ item.name }}
            </template>
            <template v-slot:item.contingent="{item}">
              <v-row align="center" justify="center">
                <v-col cols="12" class="">
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                hide-details
                                v-model="form.contingentPerDayJSON[item.value]"
                                class="pb-0 pt-0 ma-0 mx-0" type="number" outlined dense/>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </div>
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer text-right">
          <v-spacer/>
          <v-btn @click="dialog = false" class="btn btn-danger bg-danger text-light mx-auto">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>
          <v-btn :disabled="!valid||loading" :loading="loading" @click="save"
                 class="btn bg-primary text-light btn-primary mx-auto ml-2">
            {{ this.edit ? this.$t('generic.lang_save') : this.$t('generic.lang_create') }}
          </v-btn>
        </div>
      </div>
      <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>

import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from '@/config';
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import validation from "../../../mixins/validation/validation";

export default {
  name: "EventbeePoolsComponent",
  components: {
    Datatable
  },
  mixins: [mixin,validation],
  data() {
    return {
      ENDPOINTS,
      search: '',
      dialog: false,
      valid: false,
      form: {
        uuid: 0,
        name: null,
        type: 'soldTickets',
        contingentPerDayJSON:{
          mon:null,
          tue:null,
          wed:null,
          thu:null,
          fri:null,
          sat:null,
          sun:null,
        }
      },
      defaultItem: {
        uuid: 0,
        name: null,
        type: 'soldTickets',
        contingentPerDayJSON:{
          mon:null,
          tue:null,
          wed:null,
          thu:null,
          fri:null,
          sat:null,
          sun:null,
        }
      },
      loading: false,
      edit: false,
    }
  },
  watch: {
    dialog(val) {
      val || val & this.close();
    },
  },
  computed: {
    days(){
      return [
        {
          day: 0,
          name:this.$t('generic.Montag'),
          value: 'mon'

        },
        {
          day: 0,
          name:this.$t('generic.Dienstag'),
          value: 'tue'

        },
        {
          day: 0,
          name:this.$t('generic.Mittwoch'),
          value: 'wed'

        },
        {
          day: 0,
          name:this.$t('generic.Donnerstag'),
          value: 'thu'

        },
        {
          day: 0,
          name:this.$t('generic.Freitag'),
          value: 'fri'

        },
        {
          day: 0,
          name:this.$t('generic.Samstag'),
          value: 'sat'

        },
        {
          day: 0,
          name:this.$t('generic.Sonntag'),
          value: 'sun'
        },
      ]
    },
    types(){
      return [
        {
          name:this.$t('eventbee.lang_soldTickets'),
          value:'soldTickets'
        },
        {
          name:this.$t('eventbee.lang_currentCheckIn'),
          value:'currentCheckIn'
        },
      ]
    },
    formTitle: function () {
      return this.edit ? this.$t('generic.lang_edit') + ' ' + this.$t('eventbee.lang_eventPool') : this.$t('generic.lang_create') + ' ' + this.$t('eventbee.lang_eventPool');
    },
    datatableHeaders: function () {
      return [
        {
          hide:true,
          value: "id",
          sortable: false
        },
        {
          text: this.$t("eventbee.lang_poolName"),
          value: "name",
          sortable: false
        }
      ]
    },
    headers: function () {
      return [
        {
          text: this.$t("generic.lang_day"),
          value: "day",
          width:"50%",
          sortable: false
        },
        {
          text: this.$t('eventbee.lang_contingentPerDay'),
          value: "contingent",
          width:"50%",
          sortable: false
        }
      ]
    }
  },
  methods: {
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_delete') + ' ' + this.$t('eventbee.lang_pool'),
        text: this.$t('generic.lang_deleteConfirmationMSG') + ' ' + this.$t('eventbee.lang_pool'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.POOL.DELETE, {
            uuids: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.eventPools.getDataFromApi();
              self.$refs.eventPools.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    getPool(uuid){
      this.loading = false;
      this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.POOL.GET,{
        uuid: uuid
      }).then((res) => {
        if (res.status === 200) {
          this.form = Object.assign({}, res.data);
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      if (!this.$refs.eventPoolsForm.validate()) {
        return
      }
      if (this.edit) {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.POOL.UPDATE, this.form).then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close();
            this.$refs.eventPools.getDataFromApi();
            this.$refs.eventPools.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
          this.dialog = false;
        });
      } else {
        this.loading = true;
        this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.POOL.CREATE, this.form).then((res) => {
          if (res.data.status) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_success'),
              color: "success"
            });
            this.close()
            this.$refs.eventPools.getDataFromApi();
            this.$refs.eventPools.resetSelectedRows();
          } else if (res.data.status === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error"
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error"
          });
        }).finally(() => {
          this.loading = false;
          this.form = Object.assign({}, this.defaultItem);
        });
      }
    },
    editEntry(entry) {
      this.edit = true;
      this.dialog = true;
      this.getPool(entry.id);
    },
    close() {
      this.edit = false;
      this.dialog = false;
      this.form = Object.assign({}, this.defaultItem);
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
